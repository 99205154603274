import "./index.scss";

// import 'jquery-validation'
import Swiper from "swiper/bundle";
import lozad from "lozad";
// import fancybox from '@fancyapps/fancybox';
import Inputmask from "inputmask/dist/inputmask.min.js";
import { Fancybox } from "@fancyapps/ui";
// import Inputmask from "inputmask/dist/inputmask.min.js";
import Toastify from "toastify-js";

var $ = require("jquery");

jQuery(function ($) {
  function fixedMenu() {
    $(window).on("scroll", function () {
      let st = $(this).scrollTop();
      let navScroll = $(".nav").outerHeight();
      let target = $(".fixed-menu");
      if (st > navScroll) {
        target.addClass("fixed");
      } else {
        target.removeClass("fixed");
      }
    });
  }
  fixedMenu();
  $(document).on("click", "[data-modal]", function () {
    let target = "." + $(this).attr("data-modal");
    $.fancybox.open($(target), {
      modal: false,
      hideScrollbar: true,
      //smallBtn: false,
      clickOutside: "close",
    });
    return false;
  });
  $("input[name=form_name]").on("keyup", function () {
    this.value = this.value.replace(/[^а-яА-ЯёЁ -]/gi, "");
  });
  // function menu() {
  //   $(document).on("click", ".header__burger", function () {
  //     $("body").addClass("opened");
  //     $(".menu").fadeIn();
  //   });
  //   $(document).on("click", ".menu__close", function () {
  //     $("body").removeClass("opened");
  //     $(".menu").fadeOut();
  //   });
  // }
  // menu();

  Inputmask({
    mask: "+7(e99)999-99-99",
    showMaskOnHover: false,
    definitions: {
      e: {
        validator: "[0-7,9]",
      },
    },
  }).mask(document.querySelectorAll("input[name=form_phone]"));

  function modal() {
    $(".modal__close").click(function () {
      $.fancybox.close();
      return false;
    });
  }
  modal();
  function scrollTo() {
    $("[data-scroll]").click(function () {
      let target = "." + $(this).data("scroll");
      $("html, body").animate(
        {
          scrollTop: $(target).offset().top,
        },
        300
      );
      return false;
    });
  }
  scrollTo();
  function declOfNum(n, text_forms) {
    n = Math.abs(n) % 100;
    var n1 = n % 10;
    if (n > 10 && n < 20) {
      return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
      return text_forms[1];
    }
    if (n1 == 1) {
      return text_forms[0];
    }
    return text_forms[2];
  }
  if (document.getElementById("map")) {
    ymaps.ready(function () {
      setMap();
    });
  }
  function setMap() {
    let myMap;
    myMap = new ymaps.Map("map", {
      center: [53.19768, 45.208742],
      zoom: 18,
      controls: [],
    });
    let myPlacemark = new ymaps.Placemark(
      [53.197421, 45.20856],
      {},
      {
        iconLayout: "default#image",
        iconImageHref:
          "/wp-content/themes/gradek_mn/vendor/dist/img/marker1.png",
        iconImageSize: [146, 85],
        iconImageOffset: [-73, 0],
      }
    );
    let myPlacemark1 = new ymaps.Placemark(
      [53.197752, 45.209131],
      {},
      {
        iconLayout: "default#image",
        iconImageHref:
          "/wp-content/themes/gradek_mn/vendor/dist/img/marker2.png",
        iconImageSize: [146, 85],
        iconImageOffset: [-73, -85],
      }
    );
    myMap.behaviors.disable('scrollZoom');
    myMap.geoObjects.add(myPlacemark);
    myMap.geoObjects.add(myPlacemark1);
  }
  let scrollToTop = () => {
    $(".toTop").click(() => {
      $("html, body").animate({ scrollTop: 0 }, 300);
      return false;
    });
  };
  scrollToTop();
  function focusInput() {
    $('input').focus(function () {
      $(this).parent().addClass('active');
    })
    $('input').blur(function () {
      if ($(this).val().length < 1) {
        $(this).parent().removeClass('active');
      }
    })
  }
  focusInput();
});



document.addEventListener("DOMContentLoaded", function (event) {

  console.log("ready-to-go");
  /**
 * Swipers
 */
  if (document.querySelector(".news__slider__inner")) {
    new Swiper(".news__slider__inner", {
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: ".news__slider__control:nth-child(2)",
        prevEl: ".news__slider__control:nth-child(1)",
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      loop: true,
      pagination: {
        el: ".news__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
      },
    });
  }
  if (document.querySelector(".first__slider__inner")) {
    new Swiper(".first__slider__inner", {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: ".first__slider__control:nth-child(2)",
        prevEl: ".first__slider__control:nth-child(1)",
      },
      autoplay: {
        delay: 5000,
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      loop: true,
      pagination: {
        el: ".first__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
      },
    });
  }
  if (document.querySelector(".portfolio__slider__inner")) {
    new Swiper(".portfolio__slider__inner", {
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: ".portfolio__slider__control:nth-child(2)",
        prevEl: ".portfolio__slider__control:nth-child(1)",
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 3,
        },
      },
      loop: true,
      pagination: {
        el: ".portfolio__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
      },
    });
  }
  if (document.querySelector(".partners__slider__inner")) {
    new Swiper(".partners__slider__inner", {
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: ".partners__slider__control:nth-child(2)",
        prevEl: ".partners__slider__control:nth-child(1)",
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 4,
        },
      },
      loop: true,
      pagination: {
        el: ".partners__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
      },
    });
  }

  /**
   * Helpers
   */
  const cyrValue = (str) => {
    return /[^а-яА-ЯёЁ -]/gi.test(str);
  };
  const fancyboxShow = (src, type = "inline") => {
    Fancybox.show(
      [
        {
          src: src,
          type: type,
        },
      ],
      {
        autoFocus: false,
      }
    );
  };
  const toast = (title = "", text = "", type = "info") => {
    let message, messageTitle, messageText;
    message = document.createElement("div");
    messageTitle = document.createElement("p");
    messageTitle.classList.add("title");
    messageTitle.innerHTML = title;
    messageText = document.createElement("p");
    messageText.classList.add("text");
    messageText.innerHTML = text;
    message.append(messageTitle, messageText);

    Toastify({
      className: type,
      close: true,
      destination: false,
      duration: 3000,
      escapeMarkup: false,
      gravity: "bottom",
      position: "right",
      stopOnFocus: true,
      text: message.innerHTML,
      onClick: () => { },
    }).showToast();
  };
  const scrollTo = (target) => {
    document
      .querySelector(target)
      .scrollIntoView({ block: "start", behavior: "smooth" });
  };
  const addModalEvent = (selector) => {
    document.querySelectorAll(selector).forEach((el) => {
      if (el.classList.contains("modal-rendered") == false) {
        el.classList.add("modal-rendered");

        el.addEventListener("click", (evt) => {
          evt.preventDefault();

          const button = evt.currentTarget,
            target = button.hash || button.dataset.href,
            popup = document.querySelector(target),
            title = button.dataset.modalTitle,
            submit = button.dataset.modalSubmit,
            yaGoal = button.dataset.modalYagoal,
            email = button.dataset.modalEmail;

          if (title) popup.querySelector(".modal__title span").innerHTML = title;
          if (submit) popup.querySelector("input[type='submit']").value = submit;
          if (yaGoal) popup.querySelector("input[name='ya_goal']").value = yaGoal;
          if (email)
            popup.querySelector("input[name='email_title']").value = email;
          if (target) fancyboxShow(target, "inline");
        });
      }
    });
  };
  const inputValidate = (selector) => {
    document.querySelectorAll(selector).forEach((el) => {
      if (el.classList.contains("validate-rendered") == false) {
        el.classList.add("validate-rendered");

        if (selector === "input[name='agreement']") {
          el.addEventListener("change", () => {
            const status = el.checked;

            if (status) {
              el.closest("form")
                .querySelector("input[type='submit']")
                .removeAttribute("disabled");
              el.closest("form")
                .querySelector(".form__submit")
                .classList.remove("disabled");
            } else {
              el.closest("form")
                .querySelector("input[type='submit']")
                .setAttribute("disabled", "");
              el.closest("form")
                .querySelector(".form__submit")
                .classList.add("disabled");
            }
          });
        }
        if (selector === "input[name='name']") {
          let counter = 0;

          el.addEventListener("keyup", () => {
            if (cyrValue(el.value)) {
              counter++;

              if (counter > 5) {
                toast("Внимание!", "Имя должно быть на русском языке", "warning");
                counter = 0;
              }
            }

            el.value = el.value.replace(/[^а-яА-ЯёЁ -]/gi, "");
          });
          el.addEventListener("blur", () => {
            if (el.value.length > 0) {
              el.parentNode.classList.add("valid");
            } else {
              el.parentNode.classList.remove("valid");
            }
          });
        }
        if (selector === "input[name='phone']") {
          let im = new Inputmask({
            mask: "+7 (e99) 999-99-99",
            definitions: {
              e: {
                validator: "[0-7,9]",
              },
            },
          });
          im.mask(el);

          el.addEventListener("blur", () => {
            if (el.value[17] == "_" || el.value[17] == undefined) {
              toast("Внимание!", "Некорректный номер", "warning");
              el.parentNode.classList.add("error");
              el.parentNode.classList.remove("valid");
            } else {
              el.parentNode.classList.add("valid");
              el.parentNode.classList.remove("error");
            }
          });
        }
        if (selector === "input[type='email']") {
          let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
          el.addEventListener("blur", () => {
            el.parentNode.classList.remove("error");

            if (reg.test(el.value)) {
              el.parentNode.classList.add("valid");
            } else {
              el.parentNode.classList.remove("valid");

              if (el.required) {
                el.parentNode.classList.add("error");

                if (el.value.length > 0) {
                  toast("Внимание!", "Укажите корректный E-mail", "warning");
                } else {
                  toast("Внимание!", "E-mail обязателен", "warning");
                }
              }
            }
          });
        }
        if (selector === "input[name='file_attach[]']") {
          el.addEventListener("change", () => {
            let totalSize = 0;
            Object.keys(e.currentTarget.files).map((objectKey, index) => {
              totalSize = totalSize + el.files[index].size;
            });
            if (Math.round((totalSize / 1048576) * 100) / 100 > 10) {
              el.value = "";
              toast(
                "Внимание!",
                "Максимальный объём вложений - 10 мб.",
                "warning"
              );
            } else if (el.files.length > 10) {
              el.value = "";
              toast(
                "Внимание!",
                "Максимальное количество вложений - 10 шт.",
                "warning"
              );
            } else {
              el.closest("form").querySelector("#file-name").innerText =
                el.files.length +
                " шт. (" +
                Math.round((totalSize / 1048576) * 100) / 100 +
                " мб.)";
              el.closest("form")
                .querySelector("p.filename")
                .classList.add("is-loaded");
            }
          });
          el.closest("form")
            .querySelector("#file-clear")
            .addEventListener("click", (evt) => {
              el.value = "";
              evt.currentTarget
                .closest("form")
                .querySelector("p.filename")
                .classList.remove("is-loaded");
              document.querySelector("#file-name").innerText = "";
            });
        }
        if (selector === "input[type='text']" || selector === "textarea") {
          el.addEventListener("blur", () => {
            if (el.value.length > 0) {
              el.parentNode.classList.add("valid");
            } else {
              el.parentNode.classList.remove("valid");
            }
          });
        }
        if (selector === "input[required]") {
          el.addEventListener("blur", () => {
            if (el.value.length == 0) {
              toast("Внимание!", "Поле обязательно для заполнения", "warning");
            }
          });
        }
      }
    });
  };

  /**
 * Fields validation
 */
  if (document.querySelector("input[name='agreement']"))
    inputValidate("input[name='agreement']");
  if (document.querySelector("input[name='name']"))
    inputValidate("input[name='name']");
  if (document.querySelector("input[name='phone']"))
    inputValidate("input[name='phone']");
  if (document.querySelector("input[data-validate='phone_number']"))
    inputValidate("input[data-validate='phone_number']");
  if (document.querySelector("input[required]")) inputValidate("input[required]");
  if (document.querySelector("input[type='text']"))
    inputValidate("input[type='text']");
  if (document.querySelector("input[type='email']"))
    inputValidate("input[type='email']");
  if (document.querySelector("textarea")) inputValidate("textarea");
  if (document.querySelector("input[name='file_attach[]']"))
    inputValidate("input[name='file_attach[]']");

  /**
* Form
*/
  if (document.querySelector("form.fetch")) {
    document.querySelectorAll("form.fetch").forEach((form) => {
      form.addEventListener("submit", (evt) => {
        evt.preventDefault();
        let yaGoal = form.querySelector("input[name='ya_goal']").value,
          phone = form.querySelector("input[name='phone']").value
        if (phone.length[17] === "_" || phone.length === 0) {
          document.querySelector("input[name='phone']").classList.add("is-error");
        } else {
          fancyboxShow("#loading", "inline");
          if (form.querySelector("input[type='submit']"))
            form
              .querySelector("input[type='submit']")
              .setAttribute("disabled", "");
          if (form.querySelector("button[type='submit']"))
            form
              .querySelector("button[type='submit']")
              .setAttribute("disabled", "");

          let data = new FormData(form);
          leadgets("lead", data, (r) => {
            if (r.status === 1) {
              Fancybox.close();

              if (form.querySelector("input[type='submit']"))
                form
                  .querySelector("input[type='submit']")
                  .removeAttribute("disabled", "");
              if (form.querySelector("button[type='submit']"))
                form
                  .querySelector("button[type='submit']")
                  .removeAttribute("disabled", "");

              form.querySelectorAll("input").forEach((inp) => {
                if (
                  inp.type !== "submit" &&
                  inp.type !== "checkbox" &&
                  inp.type !== "radio" &&
                  inp.type !== "hidden"
                ) {
                  inp.value = "";
                  inp.parentNode.classList.remove("valid");
                }
              });
              if (form.querySelector("textarea")) {
                form.querySelector("textarea").value = "";
                form
                  .querySelector("textarea")
                  .parentNode.classList.remove("valid");
              }
              if (form.querySelector("input[type='checkbox']")) {
                form.querySelectorAll("input[type='checkbox']").forEach((el) => {
                  if (el.name != "agreement") el.checked = false;
                });
              }
              if (form.querySelector('input.download-price')) {
                document.getElementById('download-trigger').click();
              }
              setTimeout(() => {
                fancyboxShow("#thanks", "inline");
              }, 150);
              setTimeout(() => {
                Fancybox.close();
              }, 5000);

              if (typeof ym === "function" && yaMetrikaId) {
                let goal = yaMetrikaGoal ? yaMetrikaGoal : yaGoal;
                ym(yaMetrikaId, "reachGoal", goal);
                console.log("Цель достигнута: " + goal);
              }
              if (typeof gtag === "function") {
                gtag("event", "form_lead", {
                  event_category: "lead",
                  event_action: "zayavka",
                });
              }
            } else {
              toast("Внимание!", "Ошибка! " + r.message, "error");

              Fancybox.close();
              form
                .querySelector("input[type='submit']")
                .removeAttribute("disabled", "");
            }
          });
        }
      });
    });
  }

  /**
 * Animated elements
 */
  const animations_observer = lozad(".lazy-animation", {
    loaded: (el) => {
      let clientWidth = document.body.clientWidth,
        delay = (Number(el.dataset.delay) - 1) / 2;

      el.classList.add("animate__animated", "animate__fadeInUp");

      if (clientWidth > 415) {
        el.style.setProperty("animation-delay", delay + "s");
      }
    },
  });
  animations_observer.observe();

  /**
 * Mobile menu
 */
  if (document.querySelector("button.hamburger")) {
    let button = document.querySelector("button.hamburger"),
      body = document.querySelector('body'),
      mobileMenu = document.querySelector(".header__mobile"),
      mask = document.querySelector(".header__mobile__mask"),
      logo = document.querySelector('.header__logo');
    button.addEventListener("click", () => {
      button.classList.toggle("active");
      logo.classList.toggle('hide-opacity')

      if (button.classList.contains("active")) {
        mobileMenu.classList.add("active");
      } else {
        mobileMenu.classList.remove("active");
      }
    });
  }

  /**
   * Lazy load
   */
  const observer = lozad();
  observer.observe();
});